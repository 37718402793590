.responsiveTable {
  width: 100%;
}

.training-table-title {
  font-size: 2em;
  margin-bottom: 0 !important;
}
.training-links {
  margin-bottom: 10px;
}

.responsiveTable td .tdBefore {
  display: none;
}

.table-block {
  margin-bottom: 50px;
  .training-table-header {
    h5 {
      font-size: 17px;
    }
  }
}

.plan-description-bottom {
  margin-bottom: 25px;
}

.contact-us {
  text-align: center;
  margin-top: 25px;
}

@media screen and (max-width: 40em) {
  /*
      Force table elements to not behave like tables anymore
      Hide table headers (but not display: none;, for accessibility)
    */

  .responsiveTable table,
  .responsiveTable thead,
  .responsiveTable tbody,
  .responsiveTable th,
  .responsiveTable td,
  .responsiveTable tr {
    display: block;
    margin-top: -1px;
    margin-left: -1px;
  }

  .responsiveTable thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .responsiveTable tbody tr {
    padding: 0.25em;
  }

  .responsiveTable td.pivoted {
    /* Behave like a "row" */

    position: relative;
    padding-left: calc(35% + 10px) !important;
    text-align: left !important;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    border: 1px solid #eee;
  }

  .responsiveTable td .tdBefore {
    /* Now like a table header */
    position: absolute;
    display: block;

    /* Top/left values mimic padding */
    top: 15px;
    left: 1rem;
    width: calc(50% - 20px);
    white-space: pre-wrap;
    overflow-wrap: break-word;
    text-align: left !important;
    font-weight: 600;
  }
  .responsiveTable td:first-child .tdBefore {
    top: 10px;
  }
}

.training-table {
  border: 1px solid #f0f0f0;
  margin-top: 1px;
  margin-left: 1px;
}

.training-header {
  border-bottom: 1px solid #f0f0f0;

  & > tr > th {
    padding: 15px;
    border-right: 1px solid #f0f0f0;
    color: rgba(0, 0, 0, 0.85);
    background: #fafafa;
    font-weight: 500;
  }
  & > tr > th :first-child {
    border-top-left-radius: 2px;
  }
  & > tr > th :last-child {
    border-top-left-radius: 2px;
  }
}

.training-content {
  & > tr > td {
    padding: 10px;
    border-right: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    font-size: 13px;
    width: 12%;
    vertical-align: top;
  }

  & > tr > td:first-child {
    width: 8%;
  }
}

.hidden-desktop {
  display: none;
}

.training-title {
  margin-bottom: 10px;
  font-size: 15px;
  line-height: normal;
}

.training-badge {
  font-size: 12px;
}

.training-details {
  font-size: 11px;
  margin-top: 10px;
  white-space: pre-wrap;
}

.training-rest {
  text-align: center;
  padding-top: 50%;
  font-size: 15px;
  line-height: normal;
}

.training-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.align-center {
  display: flex;
  margin-top: 10px;
  justify-content: center;
}

@media only screen and (max-width: 640px) {
  .training-table {
    border: 0;
  }
  .training-header {
    border: 0;
  }
  .training-content {
    & > tr > td {
      width: auto;
    }

    & > tr > td:first-child {
      width: auto;
    }
  }
  .training-rest {
    text-align: left !important;
    padding-top: 0 !important;
  }
  .training-table-header {
    flex-direction: column;
  }

  .training-buy-container {
    width: 100%;
    margin-bottom: 10px;
  }

  .training-buy {
    width: 100%;
  }
}
