.result-wrapper {
    margin-top: 20px;
}

.result-page-button {
    display: flex;
    justify-content: flex-end;
}

.result-form-item {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.result-plan-table {
    margin-top: 20px;
    margin-bottom: 20px;
}