.begin-switcher {
  margin-left: 20px;
}

.w100 {
  width: 100%;
}

.ant-select-multiple {
  .ant-select-selection-item {
    padding-left: 4px;
    font-size: 12px;
  }
}
.main-content {
  margin-top: 20px;

  h3 {
    margin-bottom: 20px;
  }

  .ant-form-item-label {
    padding: 0 0 4px;
  }

  .datepicker-item {
    margin-right: 20px;
    margin-bottom: 0px;
  }
  .race-item {
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .heartrate-item {
    margin-right: 20px;
  }

  .volume-item {
    margin-right: 20px;
  }

  .racedate-item {
    margin-bottom: 0;
  }

  .hint-text {
    font-size: 12px;
    padding-right: 15px;
    padding-top: 5px;
    color: #00000073;
  }
}

.type {
  margin-bottom: 20px;
}
.my-perfomance-title {
  margin-bottom: 20px;

  & > h5 {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 4px;
  }
}

.my-perfomance-container {
  display: flex;
  align-items: center;
}

.my-perfomance-ok {
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-left: 20px;
}

.my-goal-ok {
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-left: 20px;
}

.switch-input {
  width: 50px !important;
}
.switch-value-container {
  align-self: flex-end;
  position: relative;
  left: -47px;
}

.switch-checked-days {
  left: -4px;
}

.switch {
  align-items: center;
}

.ant-modal-body {
  .ant-form-item-label {
    padding-bottom: 0;
  }
}

.submit {
  justify-content: center;
}
.perfomance-modal {
  width: 600px !important;

  label {
    font-size: 13px;
  }

  .perfomance-item {
    margin-left: 10px;
  }
}

.validation-message {
  color: #ff4d4f;
  display: block;
}

@media only screen and (max-width: 600px) {
  .perfomance-modal {
    width: auto;
    .perfomance-item {
      margin-left: 0;
    }
  }

  .my-perfomance-ok {
    margin-left: 0;
  }

  .my-goal-ok {
    margin-left: 10px;
  }

  .switch-input {
    width: auto;
  }
  .switch-value-container {
    left: -34px;
  }
  .switch-checked-days {
    left: 8px;
  }
}
